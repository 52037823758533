.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
}
.react-calendar {
  height: auto;
  width: auto;
}

.react-datetime-picker__calendar {
  height: auto;
  z-index: 9999 !important;
  overflow: visible !important;
}

.react-calendar__tile--now {
  background: grey !important;
}

/* .react-calendar__tile--now:enabled:hover {
  background: none !important
} */

.react-calendar__tile {
  padding: 15px 6.6667px !important;
}

.react-calendar__tile--active {
  background-color: #fa463f !important;
  border-radius: 100%;
}

.react-calendar__tile--active:enabled:hover {
  background-color: #fa463f !important;
  border-radius: 100%;
}

.react-calendar__tile:disabled {
  background-color: f0f0f0 !important;
}

/* Fixed height for the calendar */
.fixed-height {
  max-height: 400px; /* Adjust based on your design needs */
  overflow: hidden;
}

/* Scrollable time slots */
.scrollable {
  max-height: 400px; /* Same height as the calendar */
  overflow-y: auto;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f9fafb;
}

.time-slot-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.time-slot-label {
  cursor: pointer;
  margin-left: 0.5rem;
}
