.buttonBorderAnimated {
  button {
    --transition: 0.25s;
    --spark: 3s;
    --bg: black;
    background: var(--bg);
    padding: 6px 18px;
    display: grid;
    border-radius: 9999px;
    position: relative;
    box-shadow: 0 1000px 0 0 hsl(0 0% 20%) inset;
    transition: box-shadow var(--transition), background var(--transition),
      transform var(--transition);
  }

  button:hover .backdrop {
    background: rgb(20 20 20);
  }

  button:hover {
    transform: scale(1.5);
  }

  .spark {
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    rotate: 0deg;
    mask: linear-gradient(white, transparent 50%);
    animation: flip calc(var(--spark) * 2) infinite steps(2, end);
  }

  @keyframes flip {
    to {
      rotate: 360deg;
    }
  }

  .spark:before {
    content: '';
    position: absolute;
    width: 200%;
    aspect-ratio: 1;
    inset: 0 auto auto 50%;
    z-index: -1;
    translate: -50% -15%;
    rotate: 0;
    transform: rotate(-90deg);
    opacity: 1;
    background: conic-gradient(from 0deg, transparent 0 340deg, white 360deg);
    transition: opacity var(--transition);
    animation: rotate var(--spark) linear infinite both;
  }

  .backdrop {
    position: absolute;
    inset: 4px;
    background: var(--bg);
    border-radius: 9999px;
    transition: background var(--transition) opacity var(--transition);
  }

  @keyframes rotate {
    to {
      transform: rotate(90deg);
    }
  }

  .text {
    z-index: 1;
  }
}
