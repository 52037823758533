/* .scrollTable {
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 47vh;
} */

.react-bootstrap-table table {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
}

th {
  position: sticky;
  top: 0;
  background-color: rgb(106, 114, 127);
  z-index: 2;
}

.scrollTable .react-bootstrap-table-page-btns-ul {
  display: none;
}

/* date filter hide comp */
/* .date-filter-comparator.form-control {
  display: none;
} */
.react-bootstrap-table .textHeaders {
  min-width: 70px;
}
.react-bootstrap-table .textHeaderxs {
  min-width: 90px;
}
.react-bootstrap-table .textHeaderxxs {
  min-width: 110px;
}
.react-bootstrap-table .textHeaderM {
  min-width: 130px;
}
.react-bootstrap-table .textHeadersxm {
  min-width: 150px;
}
.react-bootstrap-table .textHeaderxxm {
  min-width: 170px;
}
.react-bootstrap-table .textHeaderl {
  min-width: 190px;
}
.react-bootstrap-table .textHeaderxl {
  min-width: 210px;
}
.react-bootstrap-table .textHeaderxxl {
  min-width: 230px;
}
.react-bootstrap-table .textHeader {
  min-width: 250px;
}

.react-bootstrap-table .textHeader1 {
  min-width: 270px;
}

.react-bootstrap-table .textHeader2 {
  min-width: 290px;
}

.react-bootstrap-table .textHeader3 {
  min-width: 300px;
}
.react-bootstrap-table .textHeader4 {
  min-width: 320px;
}
.react-bootstrap-table .textHeader5 {
  min-width: 340px;
}
.react-bootstrap-table .textHeader6 {
  min-width: 360px;
}
.react-bootstrap-table .textHeader7 {
  min-width: 380px;
}
.react-bootstrap-table .textHeader8 {
  min-width: 400px;
}
.react-bootstrap-table .textHeader9 {
  min-width: 420px;
}
.react-bootstrap-table .textHeader10 {
  min-width: 440px;
}
.react-bootstrap-table .textHeader11 {
  min-width: 460px;
}
.react-bootstrap-table .textHeader12 {
  min-width: 480px;
}
.react-bootstrap-table .textHeader13 {
  min-width: 500px;
}

.react-bootstrap-table .textHeader14 {
  min-width: 520px;
}

.react-bootstrap-table .textHeader15 {
  min-width: 540px;
}

.react-bootstrap-table .textHeader16 {
  min-width: 560px;
}
