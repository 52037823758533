@media print {
  html,
  body {
    margin: 0;
  }
}
@page {
  size: 80mm 50mm;
  margin: 10;
}
