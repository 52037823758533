@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* @font-face {
    font-family: 'Arima Regular';
    src: url('./library/assets/fonts/arima/arima-regular.ttf');
  } */
  @font-face {
    font-family: 'NunitoSans Regular';
    src: url('./library/assets/fonts/nunito-sans/NunitoSans-Regular.ttf');
  }
  @font-face {
    font-family: 'NunitoSans Bold';
    src: url('./library/assets/fonts/nunito-sans/NunitoSans-Bold.ttf');
  }
  @font-face {
    font-family: 'DancingScript-Bold';
    src: url('./library/assets/fonts/dancing-script/DancingScript-Bold.ttf');
  }
  @font-face {
    font-family: 'DancingScript-Regular';
    src: url('./library/assets/fonts/dancing-script/DancingScript-Regular.ttf');
  }
}

/* @layer base {
  body {
    @apply bg-white;

    &.dark {
      @apply bg-black;
    }
  }
} */

@layer components {
  table {
    @apply text-black dark:text-white;
  }
  table tr:hover {
    @apply dark:bg-white;
  }

  select {
    @apply dark:text-black;
  }
  .main {
    @apply bg-white;
  }
  .headerView {
    @apply bg-white;
  }
  .headerView {
    @apply dark:bg-boxdark;
  }
}

body {
  font-size: 14px;
  font-family: 'Arima Regular';
}

.ps__rail-y {
  background: none !important;
}

.scrollbar-container {
  width: 97% !important;
}
.dropdown-menu {
  min-width: 3rem;
}

.w-md-editor-show-live {
  height: 800 !important;
}

.bg-orange1 {
  background-color: '#EF4444';
}

/* .text-filter:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
} */

/* For reports */
.react-pdf__Page__canvas {
  min-height: 100vh !important;
  max-width: 100vw !important;
}
